import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Autoplay } from "swiper";
import { Container, Image } from 'react-bootstrap';

import img1 from '../assets/images/img1.jpg'
import img2 from '../assets/images/img2.jpg'
import img3 from '../assets/images/img3.jpg'
import img4 from '../assets/images/img4.jpg'
import img5 from '../assets/images/img5.jpg'
import img6 from '../assets/images/img6.jpeg'
import img7 from '../assets/images/img7.jpeg'


function Slider() {
    return (
        <>
            <Container fluid style={{ maxWidth: "80%" }}  >
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        // mobil cihazlarda sadece bir slide göstermek için
                        768: {
                            slidesPerView: 3,
                        }
                    }}

                >
                    <SwiperSlide>
                        <Image src={img1} alt="image1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img2} alt="image2" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img3} alt="image3" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img4} alt="image4" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img5} alt="image5" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img6} alt="image6" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image src={img7} alt="image7" />
                    </SwiperSlide>
                </Swiper >
            </Container >
        </>
    )
}

export default Slider;
