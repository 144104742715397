import React from 'react'
import { Container } from 'react-bootstrap'
import Maps from '../../components/Maps'
import { ContactForm, SocialMedia } from '../../components'
import { Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function Contact() {
    return (
        <>
            <Helmet>
                <title>İlkay Elektronik - İletişim</title>
                <meta name="description" content="İlkay Elektronik - İletişim, Çalışma saatleri hafta içi hafta sonu pazar kapalı" />
                <meta name="keywords" content="İlkay Elektronik, İletişim" />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <meta name='robots' content='index, follow' />
                <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
                <meta charSet="utf-8" />
                <meta name='language' content='Turkish' />

            </Helmet>
            <div className='products-header'>
                <h1 className='title'>İletişim</h1>
                <h3 className='title-three'>Sizin için buradayız! İhtiyaçlarınıza dair özelleştirilmiş çözümler için bize ulaşın!</h3>
            </div>
            <Container className='contact-page-body' fluid style={{ maxWidth: "80%", marginTop: "130px" }} >
                <Row>
                    <Col md={6} xs={12}>
                        <ContactForm />
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='contact-content-area'>
                            <AnimationOnScroll animateIn="animate__rubberBand" animateOnce={true} delay={300}>
                                <h1 className='title'>Nasıl Yardımcı Olabiliriz?</h1>
                            </AnimationOnScroll>
                            <p className='content'>
                                İlkay Elektronik hakkında daha fazla bilgi edinmek için lütfen formu doldurun
                                yakında sizinle iletişime geçecektir.
                            </p>
                        </div>
                    </Col>
                </Row>
                <SocialMedia />
                <Maps />
            </Container>
        </>
    )
}

export default Contact
