import React from 'react'

import '../assets/css/productsDetails.css'

import { Image } from 'react-bootstrap';



function ProductsDetailsCard(props) {
    const { img, title, content } = props;
    return (
        <>
            <div className='card-body'>
                <Image className='card-img' src={img} alt='product1' />
                <div className='card-content'>
                    <h3 className=' card-title  text-center'>{title}</h3>
                    <p className='text-center p-4'>{content}</p>
                </div>
            </div>


        </>
    )
}

export default ProductsDetailsCard;
