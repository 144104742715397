import React from 'react'
import '../assets/css/productsCard.css'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { scrollToTop } from '../Helper';



function ProductsCard(props) {
    const { img, title } = props;
    return (
        <>
            <div className="card">
                <div className="imgBox">
                    <Image className='products-img' src={img} alt="Products" />
                </div>
                <div className="contentBox">
                    <h3>{title}</h3>
                    <Link onClick={scrollToTop} to='/urunler' className='details'>Detay</Link>
                </div>
            </div>
        </>
    )
}

export default ProductsCard;
