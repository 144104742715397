import React from 'react'
import { Image } from 'react-bootstrap'
import insta from '../assets/images/insta.png'

function SocialIcon() {
    return (
        <>
            <div className='scl-img-area'>
                <a href="https://www.instagram.com/ilkay.elektronik/" target="_blank" rel="noreferrer">
                    <Image className='scl-img' src={insta} alt='scl-img' />
                </a>
            </div>

        </>
    )
}

export default SocialIcon
