import React from 'react'

function Maps() {
    return (
        <>
            <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3054.6256374098734!2d28.405590176468984!3d40.03913697853367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b60e96d28c08c7%3A0x3640388b45f92e9f!2sHamzabey%2C%20%C5%9Eht.%20Zeki%20G%C3%B6kg%C3%B6z%20Sk.%20No%3A24%2C%2016500%20Mustafakemalpa%C5%9Fa%2FBursa!5e0!3m2!1str!2str!4v1682890157085!5m2!1str!2str"
                style={{ width: "100%", height: "450px", border: "0", borderRadius: "13px" }}
            >
            </iframe>
        </>
    )
}

export default Maps
