import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../Helper';

function Footer() {

    return (
        <>
            <footer className="footer mt-5">
                <Container fluid style={{ maxWidth: "80%" }}>
                    <Row className='w-100' >
                        <Col md={4} sm={12}>
                            <h4>İletişim Bilgileri</h4>
                            <ul>
                                <li><FontAwesomeIcon icon={faLocationDot} /> Adres: Hamzabey Mh.Şehit Zeki Gököz Sk. No:24H</li>
                                <li> <a href='tel:+905355828144'> <FontAwesomeIcon icon={faPhone} /> Telefon:+90 535 582 8144 </a></li>
                                <li> <a href='mailto:pilevneilkay@hotmail.com'><FontAwesomeIcon icon={faEnvelopesBulk} /> E-posta: pilevneilkay@hotmail.com </a> </li>
                            </ul>
                        </Col>
                        <Col md={4} sm={12}>
                            <h4>Hızlı Erişim</h4>
                            <ul>
                                <li><Link to="/">Ana Sayfa</Link></li>
                                <li onClick={scrollToTop}><Link to="/urunler">Ürünlerimiz</Link></li>
                                <li onClick={scrollToTop}><Link to="/hakkimizda">Hakkımızda</Link></li>
                                <li onClick={scrollToTop}><Link to="/iletisim">İletişim</Link></li>
                            </ul>
                        </Col>
                        <Col md={4} sm={12}>
                            <h4>Çalışma Saatlerimiz</h4>
                            <ul>
                                <li>Hafta içi: 09:00 - 19:00</li>
                                <li>Cumartesi: 09:00 - 18:30</li>
                                <li>Pazar: Kapalı</li>
                            </ul>
                            <p>Tüm hakları saklıdır  &copy; 2023</p>
                        </Col>
                        <p className='last-title'>ilkay <span style={{ fontSize: "36px" }}>E</span>lektronik</p>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer;
