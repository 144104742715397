import React from 'react'
import { Navbar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { scrollToTop } from '../../Helper'

function Header() {
    return (
        <>

            <Navbar className='navbar'>
                <p className='last-title nav-logo'>ilkay <span style={{ fontSize: "36px" }}>E</span>lektronik</p>
                <NavLink onClick={scrollToTop}
                    className={({ isActive }) =>
                        isActive ? "active home-link-active" : "home-link "
                    }
                    to="/">Anasayfa</NavLink>
                <NavLink onClick={scrollToTop}
                    className={({ isActive }) =>
                        isActive ? "active navbar-active" : "a"
                    }
                    to="/urunler">Ürünler</NavLink>
                <NavLink onClick={scrollToTop}
                    className={({ isActive }) =>
                        isActive ? "active navbar-active" : "a"
                    }
                    to="/hakkimizda">Hakkımızda</NavLink>
                <NavLink onClick={scrollToTop}
                    className={({ isActive }) =>
                        isActive ? "active navbar-active" : "a"
                    }
                    to="/iletisim">İletişim</NavLink>
            </Navbar>
        </>

    )
}
export default Header;
