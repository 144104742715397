import React from 'react'

import { Button } from 'react-bootstrap';

function ButtonModel(props) {
    const { text, onClick, type, disabled } = props;
    return (
        <>
            <Button type={type} onClick={onClick} disabled={disabled} className='button-model'>{text}</Button>
        </>
    )
}

export default ButtonModel;
