import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Header from "../pages/layouts/Header";
import Footer from "../pages/layouts/Footer";
import Products from "../pages/Products/Products";
import Nopage from "../pages/NoPage/Nopage";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import SocialIcon from "../components/SocialIcon";

import { useLocation } from "react-router-dom";

import ReactGA from "react-ga4";


function Router() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-DPRKRH073R');
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/urunler" element={<Products />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="*" element={<Nopage />} />
      </Routes >
      <Footer />
      <SocialIcon />

    </>
  );
}

export default Router;