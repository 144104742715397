import React from 'react'
import '../assets/css/contactForm.css'
import InputBox from './InputBox'
import { Col, Form, Row } from 'react-bootstrap'
import ButtonModel from './ButtonModel'

import { Formik } from 'formik'
import * as Yup from 'yup'

function ContactForm() {
    return (
        <>
            <div className='contact-form'>
                <Formik
                    initialValues={
                        {
                            name: '',
                            surname: '',
                            email: '',
                            subject: '',
                            message: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Adınızı giriniz!'),
                            surname: Yup.string().required('Soyadınızı giriniz!'),
                            email: Yup.string().email('Geçerli bir email giriniz!').required('Email adresinizi giriniz!'),
                            subject: Yup.string().required('Konu giriniz!'),
                            message: Yup.string().required('Mesajınızı giriniz!')
                        })
                    }
                    onSubmit={
                        (values, { resetForm }) => {
                            console.log(values, "values");
                            setTimeout(() => {
                                resetForm();
                            }, 5000);
                        }}
                >
                    {
                        ({ values, errors, handleChange, handleSubmit, touched, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <InputBox id={"name"} type={"text"} placeholder={"Adınız"} value={values.name} onChange={handleChange} />
                                        {
                                            errors.name && touched.name && (
                                                <div className='text-danger'>{errors.name}</div>
                                            )
                                        }

                                    </Col>

                                    <Col md={6}>
                                        <InputBox id={"surname"} type={"text"} placeholder={"Soyadınız"} value={values.surname} onChange={handleChange} />
                                        {
                                            errors.surname && touched.surname && (
                                                <div className='text-danger'>{errors.surname}</div>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <InputBox id={"email"} type={"email"} placeholder={"Email"} value={values.email} onChange={handleChange} />
                                {
                                    errors.email && touched.email && (
                                        <div className='text-danger'>{errors.email}</div>
                                    )
                                }

                                <InputBox id={"subject"} type={"text"} placeholder={"Konu"} value={values.subject} onChange={handleChange} />
                                {
                                    errors.subject && touched.subject && (
                                        <div className='text-danger'>{errors.subject}</div>
                                    )
                                }

                                <textarea className='input-box' id='message' placeholder='Mesajınız' value={values.message} onChange={handleChange}></textarea>
                                {
                                    errors.message && touched.message && (
                                        <div className='text-danger'>{errors.message}</div>
                                    )
                                }
                                <div className='text-end mt-5'>
                                    <ButtonModel type={"submit"} text={"Gönder"} disabled={isSubmitting} />
                                    {isSubmitting && <div className="alert alert-success mt-2" role="alert">
                                        Mesajınız başarıyla gönderildi! Daha hızlı dönüş için telefon numaramızdan bize ulaşabilirsiniz!
                                    </div>}
                                </div>
                            </Form>
                        )
                    }
                </Formik>

            </div>
        </>
    )
}

export default ContactForm
