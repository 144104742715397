import React from 'react'
import '../assets/css/inputBox.css'


function InputBox(props) {
    const { type, placeholder, onChange, value, id } = props;
    return (
        <>
            <input id={id} onChange={onChange} value={value} type={type} className="input-box" placeholder={placeholder} />
        </>
    )
}
export default InputBox
