import React from 'react'
import { Image } from 'react-bootstrap';

import noPage from '../../assets/images/noPage.jpg'
function Nopage() {
    return (
        <>
            <Image src={noPage} alt='nopage' style={{ maxWidth: "100%", marginTop: "130px" }} />
        </>
    )
}

export default Nopage;
