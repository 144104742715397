import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ProductsDetailsCard } from '../../components';
import { Helmet } from 'react-helmet';

import product1 from '../../assets/images/productsImg/vertical/p1.jpeg';
import product2 from '../../assets/images/productsImg/vertical/p2.jpeg';
import product3 from '../../assets/images/productsImg/vertical/p3.jpeg';
import product4 from '../../assets/images/productsImg/vertical/p4.jpeg';
import product5 from '../../assets/images/productsImg/vertical/p5.jpeg';
import product6 from '../../assets/images/productsImg/vertical/p6.jpeg';
import product7 from '../../assets/images/productsImg/vertical/p7.jpeg';
import product8 from '../../assets/images/productsImg/vertical/p8.jpeg';
import product9 from '../../assets/images/productsImg/vertical/p9.jpeg';
import product10 from '../../assets/images/productsImg/vertical/p10.jpeg';
import product11 from '../../assets/images/productsImg/vertical/p11.jpeg';
import product12 from '../../assets/images/productsImg/vertical/p12.jpeg';
import product13 from '../../assets/images/productsImg/vertical/p13.jpeg';
import product14 from '../../assets/images/productsImg/vertical/p14.jpeg';
import product15 from '../../assets/images/productsImg/vertical/p15.jpeg';

function Products() {
    return (
        <>
            <Helmet>
                <title>İlkay Elektronik - Ürünler</title>
                <meta name="description" content="İlkay Elektronik - Ürünler" />
                <meta name="keywords" content="İlkay Elektronik, Ürünler" />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <meta name='robots' content='index, follow' />
                <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
                <meta name='language' content='Turkish' />
            </Helmet>
            <div className='products-header'>
                <h1 className='title'>Ürünler</h1>
                <h2 className='sub-title'>Sizler için özenle seçtiğimiz ürünlerimizi keşfedin</h2>
                <h3 className='title-three'>İyiye değil en iyiye sahip olmak için</h3>
            </div>

            <Container fluid style={{ maxWidth: "80%", marginTop: "45px" }} >
                <Row>
                    <Col md={4} >
                        <ProductsDetailsCard img={product1} title={"YE-101"} content={"Single LNB"} alt="YE-101" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product2} title={"Akıllı Tv"} content={"Android Tv"} alt="Akıllı Tv" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product3} title={"Pro-Maid x8"} content={"Akıllı Süpürge"} alt="Pro-Maid x8" />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} >
                        <ProductsDetailsCard img={product4} title={"CNet Modem"} content={"Modern Modemler"} alt="CNet Modem" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product5} title={"4K TV Stick"} content={"Yeni Modeller"} alt="4K TV Stick" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product6} title={"Mini Media Box"} content={"Yeni Tarzıyla"} alt="Mini Media Box" />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} >
                        <ProductsDetailsCard img={product7} title={"Kanky Uydu Alıcısı"} content={"Full HD uydu alıcısı"} alt="Kanky Uydu Alıcısı" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product8} title={"WebOS Smart TV"} content={"4K Ultra HD"} alt="WebOS Smart TV" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product9} title={"CNet WNIX3300L"} content={"Sinyal genişleticiler"} alt="CNet WNIX3300" />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} >
                        <ProductsDetailsCard img={product10} title={"Uydu Alıcısı"} content={"Dijital uydu alıcısı"} alt="Uydu Alıcısı" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product11} title={"Mecool KM6 4K"} content={"Android TV kutusu"} alt="Mecool KM6 4K" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product12} title={"NPCAP10"} content={"TYPE-C dönüştürücü"} alt="NPCAP10" />
                    </Col>
                </Row>

                <Row>
                    <Col md={4} >
                        <ProductsDetailsCard img={product13} title={"2053 Full HD Uydu"} content={"Çanaklı ve çanaksız kullanım"} alt="2053 Full HD Uydu" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product14} title={"4K Ultra HD"} content={"Android TV"} alt="4K Ultra HD" />
                    </Col>
                    <Col md={4}>
                        <ProductsDetailsCard img={product15} title={"2071 Full HD"} content={"Uydu alıcısı"} alt="2071 Full HD" />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Products;
