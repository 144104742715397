import React from 'react'
import { Container, Image } from 'react-bootstrap'

import aboutBanner from '../../assets/images/aboutBanner.png'
import { Helmet } from 'react-helmet'


function About() {
    return (
        <>
            <Helmet>
                <title>İlkay Elektronik - Hakkımızda</title>
                <meta name="description" content="İlkay Elektronik, müşterilerine uydu, televizyon, elektronik ekipmanlar ve benzeri teknolojik ürünlerin satışını gerçekleştiren bir işletmedir. Kendisini, yüksek kaliteli ürünleri ve müşteri memnuniyeti odaklı hizmet anlayışı ile farklılaştırmaktadır." />
                <meta name="keywords" content="İlkay Elektronik, uydu, televizyon, elektronik ekipmanlar, teknolojik ürünler, satış, yüksek kaliteli ürünler, müşteri memnuniyeti, hizmet anlayışı" />
                <meta name="author" content="İlkay Elektronik" />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <meta name='robots' content='index, follow' />
                <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
            </Helmet>

            <Image src={aboutBanner} className='about-banner' alt='about-banner' style={{ width: "100%" }} />
            <Container fluid className='about-body-container mt-5' style={{ maxWidth: "80%" }}>
                <h1 className='about-title'>Hakkımızda</h1>
                <p className='description'>
                    İlkay Elektronik, müşterilerine uydu, televizyon, elektronik ekipmanlar ve benzeri teknolojik ürünlerin satışını gerçekleştiren bir işletmedir. Kendisini, yüksek kaliteli ürünleri ve müşteri memnuniyeti odaklı hizmet anlayışı ile farklılaştırmaktadır. Müşteri memnuniyetini işletmenin öncelikleri arasında gören İlkay Elektronik, müşterilerinin ihtiyaçlarını anlamak ve onlara en uygun ürünleri sunmak için çalışmaktadır. Bu nedenle, müşterilerinin memnuniyeti ve güveni işletme için en önemli değerlerdir. İlkay Elektronik, teknolojik ürünlerin yanı sıra, aksesuarlar ve hizmetler de sunmaktadır. Müşterilerine geniş bir ürün yelpazesi sunmaktadır. İlkay Elektronik'ın amacı, müşterilerinin teknolojik ürünlerle ilgili ihtiyaçlarını karşılamak ve onlara en uygun çözümleri sunmaktır. Müşterilerine kaliteli ürünler ve hizmetler sunarak, onların güven ve memnuniyetini kazanmak için çaba göstermektedir.
                    Müşterilerimizin sorunlarına hızlı ve doğru çözümler sunarak, onların zaman ve maddi kayıplarını en aza indiriyoruz. Ayrıca, müşterilerimizin teknolojik ürünlerle ilgili ihtiyaçlarını karşılamak amacıyla, yenilikçi ve özgün çözümler geliştirmek için çalışıyoruz.
                    İlkay Elektronik olarak, müşterilerimizle uzun soluklu iş ilişkileri kurmayı hedefliyoruz. Bu nedenle, müşterilerimizin ihtiyaçlarına uygun ürünler ve hizmetler sunmak için, sürekli kendimizi yenilemekte ve geliştirmekteyiz. Teknolojinin hızla değiştiği günümüzde, müşterilerimize en güncel ürünleri ve hizmetleri sunmak için çaba gösteriyoruz.
                    İlkay Elektronik olarak, işimizi sadece ürün satmak olarak görmüyoruz. Aynı zamanda, müşterilerimizin teknolojik ürünlerle ilgili sorunlarını çözmek ve onların hayatını kolaylaştırmak için de varız. Müşteri memnuniyetini her zaman önceliğimiz olarak görmekteyiz ve bu doğrultuda hareket etmekteyiz.
                    Siz de İlkay Elektronik ailesine katılarak, teknolojik ürünlerle ilgili ihtiyaçlarınızı karşılamak için bize güvenebilirsiniz.
                </p>
            </Container>
        </>
    )
}

export default About
