import React from 'react'
import '../assets/css/socialMedia.css'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'



function SocialMedia() {
    return (
        <>
            <Row className='social-box w-100'>
                <Col md={4} xs={12}>
                    <div className='location text-center'>
                        <FontAwesomeIcon icon={faLocationDot} size='3x' color='#ffff' />
                        <h1 className='title'>Adres</h1>
                        <p className='content'>MustafaKemalPaşa</p>
                    </div>
                </Col>
                <Col md={4} xs={12}>
                    <div className='text-center'>
                        <a href='mailto:pilevneilkay@hotmail.com' style={{ textDecoration: "none" }}>
                            <FontAwesomeIcon icon={faEnvelopeCircleCheck} size='3x' color='#ffff' />
                            <h1 className='title'>E-posta</h1>
                            <p className='content'>pilevneilkay@hotmail.com</p>
                        </a>
                    </div >

                </Col>
                <Col md={4} xs={12}>
                    <div className='text-center'>
                        <a href='tel:+905355828144' style={{ textDecoration: "none" }}>
                            <FontAwesomeIcon icon={faPhone} size='3x' color='#ffff' />
                            <h1 className='title'>Telefon</h1>
                            <p className='content'>+90 535 582 8144</p>
                        </a>
                    </div >
                </Col>
            </Row>
        </>
    )
}

export default SocialMedia
