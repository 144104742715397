import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { ButtonModel, Maps, ProductsCard, Slider } from '../../components'
import homeImg from '../../assets/images/homeImg.jpg'
import product1 from '../../assets/images/productsImg/home/p1.png'
import product2 from '../../assets/images/productsImg/home/p2.png'
import product3 from '../../assets/images/productsImg/home/p3.png'
import { Helmet } from 'react-helmet'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { useNavigate } from 'react-router'
function Home() {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>İlkay Elektronik - Anasayfa</title>
                <meta name="description" content="İlkay Elektronik, müşterilerine uydu, televizyon, elektronik ekipmanlar ve benzeri teknolojik ürünlerin satışını gerçekleştiren bir işletmedir. Kendisini, yüksek kaliteli ürünleri ve müşteri memnuniyeti odaklı hizmet anlayışı ile farklılaştırmaktadır." />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <meta name='robots' content='index, follow' />
                <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
                <meta charSet="utf-8" />
                <meta name='language' content='Turkish' />
                <meta name="keywords" content="İlkay Elektronik, Anasayfa,bursa mustafakemalpaşa,bursa,mkp,mustafakemalpaşa,uydu, televizyon,anten,çanak anten,servis,next nextstar, televizyon servis,ilkay,elektrik, elektronik" />
            </Helmet>

            <div className='slider-area'>
                <Slider />
            </div>
            <Container className='home-container-body' fluid style={{ marginTop: "130px" }}>
                <section className="about_section">
                    <Container fluid className='mt-5'>
                        <Row>
                            <Col md={6}>
                                <div className="detail-box">
                                    <div className="heading_container">
                                        <AnimationOnScroll animateIn="animate__bounce" animateOnce={true}>
                                            <h1 className='about-title'>Hakkımızda</h1>
                                        </AnimationOnScroll>
                                    </div>
                                    <AnimationOnScroll animateIn="animate__bounce" animateOnce={true} >
                                        <p>
                                            İlkay Elektronik, müşterilerine uydu, televizyon, elektronik ekipmanlar ve benzeri teknolojik ürünlerin satışını gerçekleştiren bir işletmedir. Kendisini, yüksek kaliteli ürünleri ve müşteri memnuniyeti odaklı hizmet anlayışı ile farklılaştırmaktadır.
                                            Müşteri memnuniyetini işletmenin öncelikleri arasında gören İlkay Elektronik, müşterilerinin ihtiyaçlarını anlamak ve onlara en uygun ürünleri sunmak için çalışmaktadır. Bu nedenle, müşterilerinin memnuniyeti ve güveni işletme için en önemli değerlerdir.
                                            İlkay Elektronik, teknolojik ürünlerin yanı sıra, aksesuarlar ve hizmetler de sunmaktadır. Müşterilerine geniş bir ürün yelpazesi sunmaktadır.
                                            İlkay Elektronik'ın amacı, müşterilerinin teknolojik ürünlerle ilgili ihtiyaçlarını karşılamak ve onlara en uygun çözümleri sunmaktır. Müşterilerine kaliteli ürünler ve hizmetler sunarak, onların güven ve memnuniyetini kazanmak için çaba göstermektedir.<br />
                                            <span className='abaout-span'>Next&NextStar İlkay Elektronik</span>
                                        </p>
                                    </AnimationOnScroll>
                                </div>
                                <ButtonModel onClick={() => navigate('/hakkimizda')} text={"Daha Fazla..."} />
                            </Col>
                            <Col md={6}>
                                <div className="img-box">
                                    <AnimationOnScroll animateIn="animate__swing" animateOnce={false}>
                                        <Image className='right-img rounded mx-auto d-block' src={homeImg} alt='product' />
                                    </AnimationOnScroll>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section >
                <section className='mt-5 d-flex justify-content-center '>
                    <Row className='w-100'>
                        <Col md={4} sm={12} xs={12} className='res-padding' >
                            <ProductsCard img={product1} title={"Akıllı Televizyonlar"} alt="products1" />
                        </Col>
                        <Col md={4} sm={12} xs={12} className='res-padding'>
                            <ProductsCard img={product2} title={"Kablosuz Erişim(Access Point)"} alt="products2" />
                        </Col>
                        <Col md={4} sm={12} xs={12} className='res-padding'>
                            <ProductsCard img={product3} title={"9 Port Switch"} alt="products3" />
                        </Col>
                    </Row>
                </section>
                <section className='mt-5'>
                    <Maps />
                </section>
            </Container >
        </>
    )
}
export default Home;
