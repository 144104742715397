import React from 'react';

import '../src/assets/css/index.css';
import './assets/css/media.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import { store } from './store/store';
import Router from "./router/Router";

const App = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    // <Provider store={store}>
    // </Provider>
  );
}
createRoot(document.getElementById("root")).render(<App />);